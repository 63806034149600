<template>
<!--  && !isSkeletonLoading.matchStat -->
  <div class="match-detail__stats-wrapper" v-show="!isShowNoData">
    <div class="match-detail__stats-header">
      {{ $t("MATCH_STATISTICS") }}
    </div>
    <div
      v-for="(o, index) in stateList"
      :key="index"
      v-show="!isLoading && !isShowNoData"
    >
      <div
        class="match-detail__stats-card"
        v-if="o.homeScore !== null && o.awayScore !== null"
      >
        <div class="match-detail__stats-title">{{ $t(o.title) }}</div>
        <div class="display-flex">
          <div class="match-detail__stats-container mr-05rem">
            <div class="match-detail__stats-number">{{ o.homeScore }}</div>
            <div class="match-detail__stats-progress-wrapper">
              <div
                class="match-detail__stats-progress-bar"
                :style="{
                  width:
                    (parseFloat(o.homeScore) / (parseFloat(o.homeScore) + parseFloat(o.awayScore))) * 100 + '%',
                }"
              ></div>
            </div>
          </div>
          <div class="match-detail__stats-container">
            <div class="match-detail__stats-progress-wrapper right">
              <div
                class="match-detail__stats-progress-bar"
                :style="{
                  width:
                    (parseFloat(o.awayScore) / (parseFloat(o.homeScore) + parseFloat(o.awayScore))) * 100 + '%',
                }"
              ></div>
            </div>
            <div class="match-detail__stats-number right">
              {{ o.awayScore }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <Loading v-show="isLoading"></Loading> -->
  <!--  && !isLoading -->
  <div class="no-data" v-show="isShowNoData && !isSkeletonLoading.matchStat">
    {{ $t("NO_STATS") }}
  </div>

  <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.matchStat"></GeneralContentSkeleton> -->
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';

export default {
  components: {
    GeneralContentSkeleton,
  },
  data() {
    return {
      isShowNoData: true,
      isLoading: false,
      matchId: "",
      stateList: [],
      footballStateList: [
        {
          title: "SHOTS_TOTAL",
          id: "shotsTotal",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_ON_GOAL",
          id: "shotsOngoal",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_OFF_GOAL",
          id: "shotsOffgoal",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_BLOCKED",
          id: "shotsBlocked",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_INSIDE_BOX",
          id: "shotsInsidebox",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_OUTSIDE_BOX",
          id: "shotsOutsidebox",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "FOULS",
          id: "fouls",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "CORNER",
          id: "corners",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "OFFSIDES",
          id: "offsides",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "POSSESSION_TIME",
          id: "possestiontime",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "YELLOW_CARDS",
          id: "yellowcards",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "RED_CARDS",
          id: "redcards",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SAVES",
          id: "saves",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "PASSES_TOTAL",
          id: "passesTotal",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "PASSES_ACCURATE",
          id: "passesAccurate",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "PASSED_PERCENTAGE",
          id: "passesPct",
          homeScore: null,
          awayScore: null,
        },
      ],
      basketballStateList: [
        {
          title: "FIELD_GOAL_PERCENTAGE",
          id: "fgPct",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "THREE_POINT_PERCENTAGE",
          id: "tptPct",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "FREE_THROW_PERCENTAGE",
          id: "ftPct",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "REBOUNDS",
          id: "rebounds",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "ASSISTS",
          id: "assists",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "BLOCKS",
          id: "blocks",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "TURNOVERS",
          id: "turnonvers",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "STEALS",
          id: "steals",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "FOULS",
          id: "fouls",
          homeScore: null,
          awayScore: null,
        },
      ],
      matchDetailStatesLeft: {},
      matchDetailStatesRight: {},
      isSkeletonLoading: {
        matchStat: false,
      },
      isNoData: 0,
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  beforeUnmount() {
    this.stateList = [];
  },
  computed: {
    ...mapGetters([
      "currentSportType",
      "manualRefreshMatchDetail",
    ]),
  },
  watch: {
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.getMatchDetailStatData();
        }
      },
    },
    currentSportType: {
      handler(newVal, oldVal) {
        if (newVal)
          this.getMatchDetailStatData();
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.getMatchDetailStatData());
  },
  methods: {
    ...mapMutations([
      "MANUAL_REFRESH_MATCH_DETAIL",
    ]),
    ...mapActions([
      "getMatchDetailStat",
      "getBasketballMatchDetailStat",
      "manualRefreshMatchDetail",
    ]),

    async getMatchDetailStatData() {
      // this.isLoading = true;
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.matchStat = true;
      }
      let apiCall;
      if (this.currentSportType === "football")
        apiCall = this.getMatchDetailStat;
      else if (this.currentSportType === "basketball")
        apiCall = this.getBasketballMatchDetailStat;
      const result = await apiCall({ matchId: this.matchId });
      if (this.manualRefreshMatchDetail) {
        this.isSkeletonLoading.matchStat = false;
        this.verifyIfAllAPICallsDone();
      }
      // this.isLoading = false;

      if (result.result.length === 0) {
        this.isShowNoData = true;
      } else {
        this.isShowNoData = false;
        let homeResult;
        let awayResult;
        let gg = this.stateList;

        if (this.currentSportType === "football") {
          homeResult = result.result[0];
          awayResult = result.result[1];
          this.footballStatCase(homeResult, awayResult);
        } else if (this.currentSportType === "basketball") {
          homeResult = result.result.home;
          awayResult = result.result.away;
          this.basketballStatCase(homeResult, awayResult);
        }
          
        if (this.isNoData == this.stateList.length) {
          this.isShowNoData = true;
        }
      }
    },
    footballStatCase(homeResult, awayResult) {
      this.isNoData = 0;
      for (let i = 0; i < this.footballStateList.length; i++) {
        switch (this.footballStateList[i].id) {
          case "shotsTotal":
            this.footballStateList[i].homeScore = homeResult.shotsTotal;
            this.footballStateList[i].awayScore = awayResult.shotsTotal;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "shotsOngoal":
            this.footballStateList[i].homeScore = homeResult.shotsOngoal;
            this.footballStateList[i].awayScore = awayResult.shotsOngoal;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "shotsOffgoal":
            this.footballStateList[i].homeScore = homeResult.shotsOffgoal;
            this.footballStateList[i].awayScore = awayResult.shotsOffgoal;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "shotsBlocked":
            this.footballStateList[i].homeScore = homeResult.shotsBlocked;
            this.footballStateList[i].awayScore = awayResult.shotsBlocked;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "shotsInsidebox":
            this.footballStateList[i].homeScore = homeResult.shotsInsidebox;
            this.footballStateList[i].awayScore = awayResult.shotsInsidebox;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "shotsOutsidebox":
            this.footballStateList[i].homeScore = homeResult.shotsOutsidebox;
            this.footballStateList[i].awayScore = awayResult.shotsOutsidebox;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "fouls":
            this.footballStateList[i].homeScore = homeResult.fouls;
            this.footballStateList[i].awayScore = awayResult.fouls;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "corners":
            this.footballStateList[i].homeScore = homeResult.corners;
            this.footballStateList[i].awayScore = awayResult.corners;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "offsides":
            this.footballStateList[i].homeScore = homeResult.offsides;
            this.footballStateList[i].awayScore = awayResult.offsides;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "possestiontime":
            this.footballStateList[i].homeScore = homeResult.possestiontime;
            this.footballStateList[i].awayScore = awayResult.possestiontime;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "yellowcards":
            this.footballStateList[i].homeScore = homeResult.yellowcards;
            this.footballStateList[i].awayScore = awayResult.yellowcards;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "redcards":
            this.footballStateList[i].homeScore = homeResult.redcards;
            this.footballStateList[i].awayScore = awayResult.redcards;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "saves":
            this.footballStateList[i].homeScore = homeResult.saves;
            this.footballStateList[i].awayScore = awayResult.saves;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "passesTotal":
            this.footballStateList[i].homeScore = homeResult.passesTotal;
            this.footballStateList[i].awayScore = awayResult.passesTotal;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "passesAccurate":
            this.footballStateList[i].homeScore = homeResult.passesAccurate;
            this.footballStateList[i].awayScore = awayResult.passesAccurate;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
          case "passesPct":
            this.footballStateList[i].homeScore = homeResult.passesPct;
            this.footballStateList[i].awayScore = awayResult.passesPct;
            if (
              this.footballStateList[i].homeScore == null &&
              this.footballStateList[i].awayScore == null
            ) {
              this.isNoData++;
            }
            break;
        }
      }
      this.stateList = [...this.footballStateList];
    },
    basketballStatCase(homeResult, awayResult) {
      this.isNoData = 0;
      for (let i = 0; i < this.basketballStateList.length; i++) {
        switch (this.basketballStateList[i].id) {
          case "fgPct":
            this.basketballStateList[i].homeScore = homeResult.fgPct;
            this.basketballStateList[i].awayScore = awayResult.fgPct;
            if (
              this.basketballStateList[i].homeScore == "0.0" &&
              this.basketballStateList[i].awayScore == "0.0"
            ) {
              this.isNoData++;
            }
            break;
          case "tptPct":
            this.basketballStateList[i].homeScore = homeResult.tptPct;
            this.basketballStateList[i].awayScore = awayResult.tptPct;
            if (
              this.basketballStateList[i].homeScore == "0.0" &&
              this.basketballStateList[i].awayScore == "0.0"
            ) {
              this.isNoData++;
            }
            break;
          case "ftPct":
            this.basketballStateList[i].homeScore = homeResult.ftPct;
            this.basketballStateList[i].awayScore = awayResult.ftPct;
            if (
              this.basketballStateList[i].homeScore == "0.0" &&
              this.basketballStateList[i].awayScore == "0.0"
            ) {
              this.isNoData++;
            }
            break;
          case "rebounds":
            this.basketballStateList[i].homeScore = homeResult.rebounds;
            this.basketballStateList[i].awayScore = awayResult.rebounds;
            if (
              this.basketballStateList[i].homeScore == 0 &&
              this.basketballStateList[i].awayScore == 0
            ) {
              this.isNoData++;
            }
            break;
          case "assists":
            this.basketballStateList[i].homeScore = homeResult.assists;
            this.basketballStateList[i].awayScore = awayResult.assists;
            if (
              this.basketballStateList[i].homeScore == 0 &&
              this.basketballStateList[i].awayScore == 0
            ) {
              this.isNoData++;
            }
            break;
          case "blocks":
            this.basketballStateList[i].homeScore = homeResult.blocks;
            this.basketballStateList[i].awayScore = awayResult.blocks;
            if (
              this.basketballStateList[i].homeScore == 0 &&
              this.basketballStateList[i].awayScore == 0
            ) {
              this.isNoData++;
            }
            break;
          case "turnonvers":
            this.basketballStateList[i].homeScore = homeResult.turnonvers;
            this.basketballStateList[i].awayScore = awayResult.turnonvers;
            if (
              this.basketballStateList[i].homeScore == 0 &&
              this.basketballStateList[i].awayScore == 0
            ) {
              this.isNoData++;
            }
            break;
          case "steals":
            this.basketballStateList[i].homeScore = homeResult.steals;
            this.basketballStateList[i].awayScore = awayResult.steals;
            if (
              this.basketballStateList[i].homeScore == 0 &&
              this.basketballStateList[i].awayScore == 0
            ) {
              this.isNoData++;
            }
            break;
          case "fouls":
            this.basketballStateList[i].homeScore = homeResult.fouls;
            this.basketballStateList[i].awayScore = awayResult.fouls;
            if (
              this.basketballStateList[i].homeScore == 0 &&
              this.basketballStateList[i].awayScore == 0
            ) {
              this.isNoData++;
            }
            break;
        }
      }
      this.stateList = [...this.basketballStateList];
    },
    getStateList() {
      //  this.statesList
    },
    verifyIfAllAPICallsDone() {
      if (!this.isSkeletonLoading.nextFiveMatch) {
        this.MANUAL_REFRESH_MATCH_DETAIL();
      }
    },
  },
};
</script>

<style>
.match-detail__stats-wrapper {
  margin: 0 auto;
}
.match-detail__stats-header {
  padding: 0 1rem 1rem 1rem;
  /* margin: 0 1rem; */
  text-align: left;
  color: var(--color-grey-light);
  font-size: 0.8rem;
}
.match-detail__stats-card {
  margin: 0 1rem;
  color: var(--color-theme-2);
  margin: 0 1rem 1.2rem 1rem;
}
.match-detail__stats-container {
  display: flex;
  width: 50%;
  margin-right: 0.5rem;
  font-size: 0.7rem;
  align-items: center;
}
.match-detail__stats-container:last-of-type {
  margin-right: 0;
}
.match-detail__stats-title {
  font-size: 0.75rem;
  margin-bottom: 0.4rem;
}
.match-detail__stats-number {
  margin-right: 0.5rem;
  width: 1.5rem;
}
.match-detail__stats-number.right {
  margin-right: 0;
  text-align: right;
}
.match-detail__stats-progress-wrapper {
  height: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #212b30;
}
.match-detail__stats-progress-wrapper.right {
  justify-content: flex-start;
  margin-right: 0.5rem;
}
.match-detail__stats-progress-bar {
  height: 100%;
  width: 0;
  background-color: var(--color-theme-2);
}

@media (min-width: 1200px) {
  .match-detail__stats-wrapper {
    width: 70%;
  }
}
</style>
