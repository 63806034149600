<template>
  <div>
    <MatchStats />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MatchStats from "@/components/matchListings/analysis/MatchStats.vue";

export default {
  props: ["matchDetailScore"],
  components: {
    MatchStats,
  },
  computed: {
    ...mapGetters(['manualRefreshMatchDetail']),
  },
};
</script>

<style scoped>
.router-link-exact-active {
  background-color: var(--color-hover);
  border: 0.1rem solid var(--color-hover);
  color: black;
  font-weight: 700;
}
</style>
